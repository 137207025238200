import { graphql } from "gatsby"
import camelCase from "lodash/camelCase"
import React, { useState } from "react"
import Layout from "../components/Layout"
import NoRecord from "../components/NoRecord"
import Pagination from "../components/Pagination"
import RecordListItem from "../components/RecordListItem"
import SEO from "../components/Seo"
import TabBody from "../components/TabBody"
import TabHeader from "../components/TabHeader"
import { getTitle } from "../utils/getTitle"

const RecordListTemplate = ({ data, pageContext }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { langKey, category } = pageContext
  const { edges, totalCount } = data.files
  const _langKey = camelCase(langKey)
  const perPage = 25

  const indexOfLastItem = currentPage * perPage
  const indexOfFirstItem = indexOfLastItem - perPage
  const currentItems = edges.slice(indexOfFirstItem, indexOfLastItem)

  const isFirst = currentPage === 1
  const isLast =
    totalCount === 0 ? true : currentPage === Math.ceil(totalCount / perPage)

  const title = getTitle(langKey, category)

  const onNextPage = page => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  return (
    <Layout langKey={langKey}>
      <SEO title={title} />
      <TabHeader title={title} />

      <TabBody>
        {currentItems.length === 0 ? (
          <NoRecord langKey={langKey} />
        ) : (
          currentItems.map(({ node }, i) =>
            node.name[_langKey] ? (
              <RecordListItem
                key={`record-${i}`}
                {...{
                  ...node,
                  langKey: _langKey,
                  isLast: totalCount === i + 1,
                }}
              />
            ) : null
          )
        )}
      </TabBody>

      <Pagination
        {...{
          langKey,
          perPage,
          isFirst,
          isLast,
          currentPage,
          setCurrentPage: onNextPage,
          noRecord: edges.length === 0,
          totalCount: totalCount,
        }}
      />
    </Layout>
  )
}

export default RecordListTemplate

export const templateQuery = graphql`
  query RecordListTemplateQuery($category: String!) {
    files: allSanityRecord(
      filter: { category: { name: { en: { eq: $category } } } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          name {
            en
            zhHant
          }
          category {
            name {
              zhHant
              en
            }
          }
          date
          file {
            zhHant {
              asset {
                url
              }
            }
            en {
              asset {
                url
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
